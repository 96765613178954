import React from "react";
import { appStore } from "./app.store";

const mainStore = { appStore };
//创建rootStore的Context
export const rootStoreContext = React.createContext(mainStore);

export const useAppStore = () => {
  const rootStore = React.useContext(rootStoreContext);
  return rootStore.appStore;
};


