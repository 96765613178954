import React from "react";
import ReactDOM from "react-dom/client";
import "./index.less";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./public-path";
import actions from "./actions";
import { ConfigProvider, message } from "antd";
import { Auth0ProviderWithHistory } from "./Auth0ProviderWithHistory";
import { AxiosClient } from "./api/clients";

let root: ReactDOM.Root;

function render(props: any) {
  root = ReactDOM.createRoot(
    props?.container
      ? props?.container.querySelector("#sub-react-root")
      : document.querySelector("#sub-react-root")
  );

  ConfigProvider.config({
    prefixCls: "ce-ant",
  });
  
  message.config({
    getContainer: () =>
      document.getElementById("sub-react-root") as HTMLElement,
    prefixCls: "ce-ant-message",
  });

  AxiosClient.init()
  root.render(
    <React.StrictMode>
      <BrowserRouter
        basename={
          // @ts-ignore
          window.__POWERED_BY_QIANKUN__
            ? "/course-management/course/eligibility"
            : "/"
        }
      >
        <Auth0ProviderWithHistory>
          <ConfigProvider prefixCls="ce-ant">
            <App />
          </ConfigProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </React.StrictMode>
  );
}

//@ts-ignore
if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {}

export async function mount(props: any) {
  actions.setActions(props);
  render(props);
}

export async function unmount(props: any) {
  root.unmount();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
