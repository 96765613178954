import { Select, SelectProps, Spin } from "antd";
import { debounce } from "lodash";
import React, { memo } from "react";
import "./index.less";

interface SearchSelectProps extends SelectProps<string[]> {
  value?: string[];
  loading: boolean;
  onScroll: () => void;
  onSearch: (value: string) => void;
  onChange?: (value: string[]) => void;
  children?: React.ReactNode;
  loadingElement?: React.ReactNode;
}

const SearchSelect: React.FC<SearchSelectProps> = (props) => {
  const {
    value,
    loading,
    onScroll,
    onChange,
    onSearch,
    children,
    loadingElement,
    ...res
  } = props;

  const onPopupScroll: React.UIEventHandler<HTMLDivElement> = (event): void => {
    event?.persist();
    const { scrollTop, scrollHeight, offsetHeight } =
      event.target as HTMLDivElement;
    if (Math.ceil(scrollTop + offsetHeight) === scrollHeight) {
      onScroll?.();
    }
  };
  return (
    <Select
      {...res}
      allowClear
      showSearch
      value={value}
      notFoundContent={loading ? loadingElement || <Spin size="small" /> : null}
      className="search-select w-full"
      defaultActiveFirstOption={false}
      filterOption={false}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      onPopupScroll={debounce(onPopupScroll, 100)}
      onChange={onChange}
      onSearch={debounce(onSearch, 100)}
    >
      {children}
    </Select>
  );
};

export default memo(SearchSelect);
