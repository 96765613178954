import React, { MouseEventHandler, ReactNode } from "react";

interface CommonBadgeProps {
  label?: string | ReactNode;
  className?: string;
  textColor?: string;
  bgColor?: string;
  onClick?: MouseEventHandler<any>;
}

const CommonBadge: React.FC<CommonBadgeProps> = (props) => {
  const {
    label = "Badge",
    className = "",
    textColor = "#FFFFFF",
    bgColor = "#5B9DFF",
    onClick,
  } = props;
  const initClassName = "inline-flex items-center ";

  return (
    <div
      className={initClassName.concat(className)}
      onClick={onClick}
      style={{
        color: textColor,
        backgroundColor: bgColor,
        borderRadius: "8px",
      }}
    >
      <div className="px-2 py-1 text-3">{label}</div>
    </div>
  );
};

export default CommonBadge;
