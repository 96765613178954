// import { useAuth0 } from "@/Auth0Wrapper";
import jwtDecode from "jwt-decode";
import { OAuthPermission } from "./permission";

interface IUseAccessControl {
  hasSomePermissions: (permissions: OAuthPermission[]) => boolean;
  hasAllPermissions: (permissions: OAuthPermission[]) => boolean;
}

/**
 *
 * @returns {object} { hasSomePermissions, hasAllPermissions }
 *  hasSomePermissions - if it has one of required permissions
 *  hasSomePermissions - if it has all of required permissions
 */
export function useAccessControl(): IUseAccessControl {
  const token = sessionStorage.getItem("token") || "";
  const decodedToken: { permissions: OAuthPermission[] } = jwtDecode(token);
  const permissionsAllowed = decodedToken.permissions || [];

  /**
   * to check if it has one of required permissions
   * @param {OAuthPermission[]} - permissions to check
   * @returns {boolean}
   */
  const hasSomePermissions = (permissions: OAuthPermission[]): boolean => {
    const result = permissions.some((permission) =>
      permissionsAllowed.includes(permission)
    );
    return result;
  };

  /**
   * to check if it has all of required permissions
   * @param permissions {EnumPermission[]} - permissions to check
   * @returns {boolean}
   */
  const hasAllPermissions = (permissions: OAuthPermission[]): boolean => {
    const result = permissions.every((permission) =>
      permissionsAllowed.includes(permission)
    );
    return result;
  };

  return {
    hasSomePermissions,
    hasAllPermissions,
  };
}
