import { appStore } from "./mobx/app.store";

function emptyAction(...props: any[]) {
  console.warn("Current execute action is empty!");
}

class Actions {
  actions = {
    onGlobalStateChange: emptyAction,
    setGlobalState: emptyAction,
  };

  setActions(actions: any) {
    this.actions = actions;
    appStore.setEligibilityFlow(null);
    appStore.resetNodes();
    appStore.setCurrentCourseId(actions.courseId);
    appStore.setCourseDetail({ _id: "", nameShort: "", avatarUrl: "" });
  }

  onGlobalStateChange(...args: any[]) {
    return this.actions.onGlobalStateChange(...args);
  }

  setGlobalState(...args: any[]) {
    return this.actions.setGlobalState(...args);
  }
}

const actions = new Actions();
export default actions;
