import { cloneDeep } from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import {
  RawNodeDatum,
  TreeNodeDatum,
} from "react-d3-tree/lib/types/types/common";

import {
  changeCriteria,
  changeOutcome,
  changeServerResultToTree,
  editFlowNode,
  editOutcomeNode,
  removeFlowNode,
} from "./utils";
import {
  CourseEligibilityFlow,
  FlowNodeCommand,
  FlowOutcomeCommand,
} from "./type";
import { Course, ReferenceData } from "../api/course/types";

export enum LevelType {
  Level = "Level",
  TimePeriod = "Time Period",
  PreRequisite = "Pre-requisite",
  Enrollment = "Enrollment",
  ContentOverlaps = "Content Overlaps",
  Result = "Result",
}
export interface LevelProps {
  label: string;
  key: string;
}
export interface TestFlowProps {
  show: boolean;
}
export interface RequisiteItemProps {
  courseId: string;
  range: {
    start: Date;
    end: Date;
  };
  isWhere: boolean;
  canSwitch: boolean;
}
export default class AppStore {
  constructor() {
    // 建议使用这种方式，自动识别类型，不需要再加前缀
    makeAutoObservable(this);
  }
  currentNodes: RawNodeDatum = {
    name: "root",
    children: [
      {
        name: "start",
        children: [],
        attributes: {
          type: "",
          childrenType: "passNode",
          parentName: "root",
        },
      },
    ],
    attributes: {
      type: "",
      isTopNode: true,
    },
  };
  userToken = "";
  currentFlow: CourseEligibilityFlow | null = null;
  currentCourseId: string = "";
  courseDetail: { nameShort: string; _id: string; avatarUrl: string } = {
    nameShort: "",
    _id: "",
    avatarUrl: "",
  };
  courses: Course[] = [];
  references: ReferenceData[] = [];
  isOpenEligibilityFlow: boolean = false;

  setCourses = (val: Course[]) => {
    this.courses = val;
  };

  setReferences = (val: ReferenceData[]) => {
    this.references = val;
  };

  setCourseDetail = (course: {
    nameShort: string;
    _id: string;
    avatarUrl: string;
  }) => {
    this.courseDetail = course;
  };
  setUserToken = (token: string) => {
    this.userToken = token;
  };
  resetNodes = () => {
    this.currentNodes = {
      name: "root",
      children: [
        {
          name: "start",
          children: [],
          attributes: {
            type: "",
            childrenType: "passNode",
            parentName: "root",
          },
        },
      ],
      attributes: {
        type: "",
        isTopNode: true,
      },
    };
  };
  setCurrentCourseId = (courseId: string) => {
    if (courseId) {
      this.currentCourseId = courseId;
    }
  };
  initialCurrentNodes = (currentFlow: FlowNodeCommand) => {
    const defaultNode = this.currentNodes;
    const node = changeServerResultToTree(currentFlow, "start", true);
    node.name = "start";
    this.currentNodes = { ...defaultNode, children: [node] };
  };

  setEligibilityFlow = (flow: CourseEligibilityFlow | null) => {
    this.currentFlow = flow;
  };

  setOpenEligibilityFlow = (bool: boolean) => {
    this.isOpenEligibilityFlow = bool;
  };

  addCriteria = (nodeDatum: TreeNodeDatum, flow: FlowNodeCommand) => {
    const node = cloneDeep(this.currentNodes);
    const newNode = changeCriteria(toJS(node), flow, nodeDatum.name);
    if (newNode) {
      this.currentNodes = newNode;
    }
  };

  addOutcome = (nodeDatum: TreeNodeDatum, value: FlowOutcomeCommand) => {
    const node = cloneDeep(this.currentNodes);
    const newNode = changeOutcome(toJS(node), nodeDatum.name, value);
    if (newNode) {
      this.currentNodes = newNode;
    }
  };

  removeNode = (nodeName: string) => {
    const node = cloneDeep(this.currentNodes);
    const newNode = removeFlowNode(toJS(node), nodeName);
    if (newNode) {
      this.currentNodes = newNode;
    }
  };

  editCriteria = (nodeDatum: TreeNodeDatum, value: FlowNodeCommand) => {
    const node = cloneDeep(this.currentNodes);
    const newNode = editFlowNode(toJS(node), nodeDatum.name, value);
    if (newNode) {
      this.currentNodes = newNode;
    }
  };

  editOutcome = (nodeDatum: TreeNodeDatum, value: FlowOutcomeCommand) => {
    const node = cloneDeep(this.currentNodes);
    const newNode = editOutcomeNode(toJS(node), nodeDatum.name, value);
    if (newNode) {
      this.currentNodes = newNode;
    }
  };
}
export const appStore = new AppStore();
