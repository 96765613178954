import { cloneDeep, isEmpty, omit } from "lodash";
import { toJS } from "mobx";
import { RawNodeDatum } from "react-d3-tree/lib/types/types/common";
import uuid from "react-uuid";

import {
  EnrollmentHistoryCheckExpressionCommand,
  FlowNodeCommand,
  FlowNodeType,
  FlowOutcomeCommand,
  LogicalOperator,
} from "./type";
import moment from "moment";
export const dateFormat = "DD MMM";

export const checkNodesType = (obj: RawNodeDatum): boolean => {
  let originObject: RawNodeDatum = cloneDeep(obj);

  if (!originObject.attributes?.type) {
    return true;
  }

  if (originObject.children) {
    for (let child of originObject.children) {
      if (checkNodesType(child)) {
        return true;
      }
    }
  }

  return false;
};

export const checkLearntHistory = (obj: RawNodeDatum): boolean => {
  let originObject: RawNodeDatum = cloneDeep(obj);

  if (originObject.attributes?.type === FlowNodeType.LearntHistory) {
    return true;
  }

  if (originObject.children) {
    for (let child of originObject.children) {
      if (checkLearntHistory(child)) {
        return true;
      }
    }
  }

  return false;
};

// transfer nodes to params
export const convertNodesToParams = (obj: RawNodeDatum) => {
  let originObject: RawNodeDatum = cloneDeep(obj);
  let newObject: any = toJS(cloneDeep(obj));
  if (typeof originObject === "object") {
    const attributes = originObject.attributes;
    newObject = omit(newObject, "attributes", "children", "name");

    if (attributes?.type === FlowNodeType.AcademicStream) {
      newObject.operator = attributes.operator || LogicalOperator.Or;
      newObject.type = FlowNodeType.AcademicStream;
      newObject.academicStreamCheckExpressions = JSON.parse(
        attributes.academicStreamCheckExpressions as string
      );
    }

    if (attributes?.type === FlowNodeType.EnrollmentHistory) {
      newObject.operator = attributes.operator || LogicalOperator.Or;
      newObject.type = FlowNodeType.EnrollmentHistory;
      const enrollmentHistoryCheckExpressions: EnrollmentHistoryCheckExpressionCommand[] =
        JSON.parse(attributes.enrollmentHistoryCheckExpressions as string);
      newObject.enrollmentHistoryCheckExpressions =
        enrollmentHistoryCheckExpressions?.map((i) => ({
          courseId: i.courseId,
          startDate: i.startDate ? moment(i.startDate).startOf("D") : null,
          endDate: i.endDate ? moment(i.endDate).endOf("D") : null,
          operator: i.operator,
        }));
    }

    if (attributes?.type === FlowNodeType.LearntHistory) {
      newObject.type = FlowNodeType.LearntHistory;
      newObject.learntHistoryExpression = JSON.parse(
        attributes.learntHistoryExpression as string
      );
    }

    if (attributes?.type === FlowNodeType.Outcome) {
      newObject.type = FlowNodeType.Outcome;
      newObject.outcome = JSON.parse(attributes.outcome as string);
    }

    const passNode = originObject.children?.find(
      (i) => i.attributes?.childrenType === "passNode"
    );
    const failNode = originObject.children?.find(
      (i) => i.attributes?.childrenType === "failNode"
    );

    if (passNode) {
      newObject.passNode = convertNodesToParams(passNode)?.type
        ? convertNodesToParams(passNode)
        : undefined;
    }
    if (failNode) {
      newObject.failNode = convertNodesToParams(failNode)?.type
        ? convertNodesToParams(failNode)
        : undefined;
    }
  }

  return newObject;
};

// transfer params to nodes
export const changeServerResultToTree = (
  json: FlowNodeCommand,
  parentId: string,
  isStart?: boolean
) => {
  const passNode = json?.passNode;
  const failNode = json?.failNode;
  const currentId = isStart ? "start" : uuid();

  let node: RawNodeDatum = {
    name: currentId,
    attributes: {
      type: json.type,
      parentName: parentId,
      operator: json.operator as string,
    },
  };

  if (!isEmpty(json?.academicStreamCheckExpressions)) {
    (node.attributes as any).academicStreamCheckExpressions = JSON.stringify(
      json.academicStreamCheckExpressions
    );
  }

  if (!isEmpty(json?.enrollmentHistoryCheckExpressions)) {
    (node.attributes as any).enrollmentHistoryCheckExpressions = JSON.stringify(
      json.enrollmentHistoryCheckExpressions
    );
  }

  if (!isEmpty(json?.learntHistoryExpression)) {
    (node.attributes as any).learntHistoryExpression = JSON.stringify(
      json.learntHistoryExpression
    );
  }

  if (!isEmpty(json?.outcome)) {
    (node.attributes as any).outcome = JSON.stringify(json.outcome);
  }

  if (passNode) {
    const passChildren = changeServerResultToTree(passNode, currentId);
    const attributes = passChildren.attributes;
    if (attributes) {
      attributes.childrenType = "passNode";
    }
    node.children = [passChildren];
  } else if (
    node?.attributes?.type &&
    node?.attributes?.type !== FlowNodeType.Outcome
  ) {
    // init add flow node
    node.children = [
      {
        name: uuid(),
        children: [],
        attributes: {
          type: "",
          childrenType: "passNode",
          parentName: currentId,
        },
      },
    ];
  }

  if (failNode) {
    const failChildren = changeServerResultToTree(failNode, currentId);
    const attributes = failChildren.attributes;
    if (attributes) {
      attributes.childrenType = "failNode";
    }
    node.children = [...(node.children || []), failChildren];
  } else if (
    node?.attributes?.type &&
    node?.attributes?.type !== FlowNodeType.Outcome
  ) {
    // init add flow node
    node.children = [
      ...(node.children || []),
      {
        name: uuid(),
        children: [],
        attributes: {
          type: "",
          childrenType: "failNode",
          parentName: currentId,
        },
      },
    ];
  }
  return node;
};

const formatCriteriaAttributes = (obj: RawNodeDatum, flow: FlowNodeCommand) => {
  const attributes: any = {
    ...obj.attributes,
    type: flow.type,
    operator: flow.operator as string,
    academicStreamCheckExpressions: undefined,
    enrollmentHistoryCheckExpressions: undefined,
    learntHistoryExpression: undefined,
    outcome: undefined,
  };

  if (flow?.type === FlowNodeType.AcademicStream) {
    attributes.academicStreamCheckExpressions = JSON.stringify(
      flow?.academicStreamCheckExpressions || ""
    );
  }

  if (flow?.type === FlowNodeType.EnrollmentHistory) {
    attributes.enrollmentHistoryCheckExpressions = JSON.stringify(
      flow.enrollmentHistoryCheckExpressions || ""
    );
  }

  if (flow?.type === FlowNodeType.LearntHistory) {
    attributes.learntHistoryExpression = JSON.stringify(
      flow.learntHistoryExpression || ""
    );
  }

  return attributes;
};

const resetChildren = (parentName: string) => {
  const children = [
    {
      name: uuid(),
      children: [],
      attributes: {
        type: "",
        childrenType: "passNode",
        parentName,
      },
    },
    {
      name: uuid(),
      children: [],
      attributes: {
        type: "",
        childrenType: "failNode",
        parentName,
      },
    },
  ];

  return children;
};

export const changeCriteria = (
  obj: RawNodeDatum,
  flow: FlowNodeCommand,
  name: string
) => {
  if (typeof obj === "object") {
    if (obj.name === name) {
      obj.attributes = formatCriteriaAttributes(obj, flow);
      obj.children = resetChildren(obj.name);

      return obj;
    }

    if (isEmpty(obj.children)) {
      return obj;
    }

    for (let index = 0; index < (obj.children?.length ?? 0); index++) {
      const chidren = obj?.children ?? [];
      const element = chidren[index];
      if (element.name === name) {
        element.attributes = formatCriteriaAttributes(element, flow);
        element.children = resetChildren(element.name);
      } else {
        changeCriteria(element, flow, name);
      }
    }
  }
  return obj;
};

export const changeOutcome = (
  obj: RawNodeDatum,
  name: string,
  value: FlowOutcomeCommand
) => {
  if (typeof obj === "object") {
    if (obj.name === name) {
      obj.attributes = {
        ...obj.attributes,
        type: FlowNodeType.Outcome,
        outcome: JSON.stringify(value),
      };
    }
    if (isEmpty(obj.children)) {
      return obj;
    }

    for (let index = 0; index < (obj.children?.length ?? 0); index++) {
      const chidren = obj?.children ?? [];
      const element = chidren[index];
      if (element.name === name) {
        element.attributes = {
          ...element.attributes,
          type: FlowNodeType.Outcome,
          outcome: JSON.stringify(value),
        };
      } else {
        changeOutcome(element, name, value);
      }
    }
  }
  return obj;
};

export const removeFlowNode = (obj: RawNodeDatum, name: string) => {
  if (typeof obj === "object") {
    if (obj.name === name) {
      obj.children = [];
      obj.attributes = {
        type: "",
        childrenType: (obj.attributes as any).childrenType,
        parentName: (obj.attributes as any).parentName,
      };
    }

    if (isEmpty(obj.children)) return obj;

    for (let index = 0; index < (obj.children?.length ?? 0); index++) {
      const chidren = obj?.children ?? [];
      const element = chidren[index];
      if (element.name === name) {
        element.children = [];
        element.attributes = {
          type: "",
          childrenType: (element.attributes as any).childrenType,
          parentName: (element.attributes as any).parentName,
        };
      } else {
        removeFlowNode(element, name);
      }
    }
  }
  return obj;
};

export const editFlowNode = (
  obj: RawNodeDatum,
  name: string,
  flow: FlowNodeCommand
) => {
  if (typeof obj === "object") {
    if (obj.name === name) {
      obj.attributes = formatCriteriaAttributes(obj, flow);
    }

    if (isEmpty(obj.children)) return obj;

    for (let index = 0; index < (obj.children?.length ?? 0); index++) {
      const chidren = obj?.children ?? [];
      const element = chidren[index];
      if (element.name === name) {
        element.attributes = formatCriteriaAttributes(element, flow);
      } else {
        editFlowNode(element, name, flow);
      }
    }
  }
  return obj;
};

export const editOutcomeNode = (
  obj: RawNodeDatum,
  name: string,
  flow: FlowOutcomeCommand
) => {
  if (typeof obj === "object") {
    if (obj.name === name) {
      obj.attributes = {
        ...obj.attributes,
        type: FlowNodeType.Outcome,
        outcome: JSON.stringify(flow),
      };
    }

    if (isEmpty(obj.children)) return obj;

    for (let index = 0; index < (obj.children?.length ?? 0); index++) {
      const chidren = obj?.children ?? [];
      const element = chidren[index];
      if (element.name === name) {
        element.attributes = {
          ...element.attributes,
          type: FlowNodeType.Outcome,
          outcome: JSON.stringify(flow),
        };
      } else {
        editOutcomeNode(element, name, flow);
      }
    }
  }
  return obj;
};
