const listToMap = <T>(list: T[], key: string): Map<string, T> => {
  const map = new Map<string, T>();
  list.forEach((e) => {
    if (!e) {
      return;
    }
    map.set((e as any)[key].toString(), e);
  });
  return map;
};

export { listToMap };
