import {
  CheckCircleFilled,
  CloseCircleFilled,
  FileDoneOutlined,
  SettingFilled,
} from "@ant-design/icons";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { colors } from "../../../constant";
import { useAppStore } from "../../../mobx";
import uuid from "react-uuid";
import { isEmpty } from "lodash";
import { Dropdown } from "antd";
import { TreeNodeDatum } from "react-d3-tree/lib/types/types/common";
import { ReactComponent as IconDelete } from "../../../assets/IconDelete.svg";
import { ReactComponent as IconEdit } from "../../../assets/IconEdit.svg";
import { FlowOutcomeCommand } from "../../../mobx/type";
import { openConfirmModal } from "../../ConfirmModal";
import useManageOutcome from "./useManageOutcome";

export interface NodeProps {
  nodeDatum: TreeNodeDatum;
}
function OutcomeNode({ nodeDatum }: NodeProps) {
  const { isOpenEligibilityFlow, removeNode } = useAppStore();
  const [currentId, setCurrentId] = useState("");
  const { modal: outcomeModal, onOpenManageOutcome } = useManageOutcome(
    nodeDatum,
    true
  );

  useEffect(() => {
    if (isEmpty(currentId)) {
      setCurrentId(uuid());
    }
  }, [currentId]);

  const outcome: FlowOutcomeCommand = useMemo(() => {
    if (nodeDatum?.attributes?.outcome) {
      return JSON.parse(nodeDatum?.attributes?.outcome as string);
    }
    return {};
  }, [nodeDatum?.attributes?.outcome]);

  return (
    <div
      className=" flex items-center flex-col"
      onScroll={(event) => {
        event?.stopPropagation();
      }}
      onMouseEnter={(event) => {
        event?.stopPropagation();
        event?.preventDefault();
      }}
    >
      {nodeDatum.name !== "start" && (
        <>
          {nodeDatum?.attributes?.childrenType === "passNode" && (
            <span className="ml-8">Yes</span>
          )}
          {nodeDatum?.attributes?.childrenType === "failNode" && (
            <span className="ml-8">No</span>
          )}
        </>
      )}
      <div className="flex bg-white rounded-default flex-col flex-1 w-full">
        <div className="flex flex-row justify-between flex-1 p-4 border-b-default border-b-gray-400">
          <div className="flex flex-row items-center">
            <FileDoneOutlined style={{ color: colors.primary.blue }} />
            <div className="ml-2 text-base font-semibold">Outcome</div>
          </div>
          {isOpenEligibilityFlow && (
            <Dropdown
              overlayClassName="w-32"
              menu={{
                items: [
                  {
                    label: "Edit",
                    key: "Edit",
                    icon: <IconEdit className="cursor-pointer" />,
                  },
                  {
                    label: "Delete",
                    key: "Delete",
                    icon: <IconDelete className="cursor-pointer" />,
                  },
                ],
                onClick: (info) => {
                  if (info.key === "Delete") {
                    openConfirmModal({
                      title: "Remove Node",
                      callback: async () => {
                        removeNode(nodeDatum.name);
                      },
                    });
                  } else {
                    onOpenManageOutcome();
                  }
                },
              }}
              getPopupContainer={() =>
                document.getElementById("sub-react-root") as HTMLElement
              }
            >
              <SettingFilled style={{ color: colors.primary.navy }} />
            </Dropdown>
          )}
        </div>
        <div className="p-4">
          {outcome?.eligibility ? (
            <div className="flex items-baseline font-semibold">
              <CheckCircleFilled
                style={{ color: colors.semantics.green }}
                className="!pl-0 pr-1"
              />
              Eligible
            </div>
          ) : (
            <div className="flex items-baseline font-semibold">
              <CloseCircleFilled
                style={{ color: colors.semantics.red }}
                className="!pl-0 pr-1"
              />
              Not Eligible
            </div>
          )}

          <div
            style={{
              backgroundColor: "#EAF2FF",
              maxHeight: 100,
            }}
            className="py-2 mt-2 overflow-auto rounded-md"
          >
            <div className="px-4">
              <span className="font-semibold">Message:</span> {outcome?.message}
            </div>
          </div>
        </div>
      </div>
      {outcomeModal}
    </div>
  );
}

export default observer(OutcomeNode);
