import { Modal } from "antd";

const { confirm } = Modal;

const openConfirmModal = ({
  title = "Confirmation",
  callback,
}: {
  title?: string;
  callback: () => Promise<void>;
}) => {
  confirm({
    title: (
      <div className="check-trial-in-course-modal-header c-text-sm p-6 border rounded-lg font-semibold">
        {title}
      </div>
    ),
    width: 520,
    icon: <></>,
    closable: true,
    wrapClassName: "check-trial-in-course-modal",
    content: (
      <div className="flex flex-col gap-6 px-6 pt-6">
        Are you sure you want to proceed?
      </div>
    ),
    okText: "Yes",
    cancelText: "No",
    onOk: () => {
      return new Promise(async (resolve, reject) => {
        try {
          await callback();
          return resolve(true);
        } catch (error) {
          return reject();
        }
      }).catch(() => console.log("Oops errors!"));
    },
    okButtonProps: {
      prefixCls: "ce-ant-btn",
    },
    cancelButtonProps: {
      prefixCls: "ce-ant-btn",
    },
    getContainer() {
      return document.getElementById("sub-react-root") as HTMLElement;
    },
    prefixCls: "ce-ant-modal",
  });
};

export { openConfirmModal };
