export enum UserRoleType {
  Predefined = "PREDEFINED",
  Customized = "CUSTOMIZED",
}

export type UserRole = {
  _id: string;
  name: string;
  type: UserRoleType;
  description: string;
  permissions: string[];
};

export type UserRoleFilter = {
  name?: string;
};

export type Page<T> = {
  total: number;
  pageSize: number;
  pageIndex: number;
  items: T[];
};

export type PageQuery = {
  pageIndex?: number;
  pageSize?: number;
  sortBy?: string;
  desc?: boolean;
};

export enum CourseType {
  Regular = "REGULAR",
  Workshop = "WORKSHOP",
}

export type Course = {
  avatarUrl?: string;
  name: string;
  type: CourseType;
  nameShort: string;
  _id: string;
  createdBy?: string;
  lastModifiedBy?: string;
  isDeleted: boolean;
  deletedAt?: Date;
  createDateTime: Date;
  lastModifiedDateTime?: Date;
  session?: any;
  isModified?: boolean;
};

export type ReferenceData = {
  category: ReferenceDataCategory;
  order?: number;
  key: string;
  value: string;
  isArchived: boolean;
  _id: string;
};

export enum ReferenceDataCategory {
  AcademicStream = "academicStream",
  ExamType = "examType",
  Gender = "gender",
  RewardCategory = "rewardCategory",
  Salutation = "salutation",
  NameOfBank = "nameOfBank",
  PaymentProcessor = "paymentProcessor",
  Subject = "subject",
}

export type ExecuteCourseEligibilityFlowQuery = {
  courseId?: string;
  studentId: string;
  nodeNo?: number;
  userResponse?: boolean;
};
