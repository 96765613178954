import { message } from "antd";
import axios, {
  AxiosInstance,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from "axios";
import qs from "qs";

export interface InitProps {
  request?: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig;
  response?: (config: AxiosResponse) => AxiosResponse;
  responseError?: (config: any) => any;
}

const isArrayOfTypeString = (arr: Array<any>): boolean =>
  Array.isArray(arr) && arr.every((item) => typeof item === "string");

const defaultInitProps: InitProps = {
  request: (config) => config,
  response: (config) => config?.data,
  responseError: (err: any) => {
    message.error(
      (err.response.data as any)?.message ||
        (err.response.data as any)?.error?.message ||
        err.message
    );
  },
};

export class BaseApiClient {
  public static instance: AxiosInstance;

  public static init(config?: CreateAxiosDefaults & InitProps): void {
    const {
      request = defaultInitProps.request,
      response = defaultInitProps.response,
      responseError = defaultInitProps.responseError,
      ...res
    } = config || {};
    const instance = axios.create({ ...res });

    // @ts-ignore
    instance.interceptors.request.use((c) => request(c));

    instance.interceptors.response.use(
      // @ts-ignore
      (c) => response(c),
      (error) => {
        responseError?.(error);
      }
    );

    if (!this.instance) {
      this.instance = instance;
    }
  }

  public static get<T, U>(url: string, params?: T): Promise<U> {
    return new Promise((resolve, reject) => {
      this.instance
        .get(url, {
          params,
          paramsSerializer: (query) => {
            const result: Record<string, any> = {};
            for (const key in query) {
              if (isArrayOfTypeString(query[key])) {
                result[key] = query[key].join(",");
              } else {
                result[key] = query[key];
              }
            }
            return qs.stringify(result);
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static post<T, U>(url: string, data: T | null, params?: any): Promise<U> {
    return new Promise((resolve, reject) => {
      this.instance
        .post(url, data, { params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  }

  public static put<T, U>(url: string, data?: T): Promise<U> {
    return new Promise((resolve, reject) => {
      this.instance
        .put(url, data)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  }

  public static setHeaderOptions(potions: RawAxiosRequestHeaders): void {
    if (!this.instance) {
      throw new Error("Please create an axios instance first!");
    }

    this.instance.defaults.headers.options = {
      ...this.instance.defaults.headers.options,
      ...potions,
    };
  }

  public static setAuthorization(authorization: string): void {
    if (!this.instance) {
      throw new Error("Please create an axios instance first!");
    }

    if (!authorization) {
      throw new Error("No token");
    }

    this.instance.defaults.headers.Authorization = `Bearer ${authorization}`;
  }
}
