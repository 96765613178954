import { TreeNodeDatum } from "react-d3-tree/lib/types/types/common";
import { PlusCircleFilled } from "@ant-design/icons";
import { FC, useMemo } from "react";
import useManageCriteria from "./Criteria/useManageCriteria";
import useManageOutcome from "./Outcome/useManageOutcome";
import { Button, Dropdown } from "antd";
import { useAppStore } from "../../mobx";
import { observer } from "mobx-react";

export interface NodeProps {
  nodeDatum: TreeNodeDatum;
}
const AddFlowNode: FC<NodeProps> = ({ nodeDatum }) => {
  const { isOpenEligibilityFlow } = useAppStore();
  const { modal, onOpenManageCriteria } = useManageCriteria(nodeDatum);
  const { modal: outcomeModal, onOpenManageOutcome } =
    useManageOutcome(nodeDatum);

  const menuItems = useMemo(() => {
    if (nodeDatum?.name === "start") {
      return [
        {
          label: "Add Criteria",
          key: "Add Criteria",
        },
      ];
    }
    return [
      {
        label: "Add Criteria",
        key: "Add Criteria",
      },
      {
        label: "Add Outcome",
        key: "Add Outcome",
      },
    ];
  }, [nodeDatum?.name]);

  return (
    <div>
      {nodeDatum.name !== "start" && (
        <>
          {nodeDatum?.attributes?.childrenType === "passNode" && (
            <span className="ml-8">Yes</span>
          )}
          {nodeDatum?.attributes?.childrenType === "failNode" && (
            <span className="ml-8">No</span>
          )}
        </>
      )}
      {isOpenEligibilityFlow ? (
        <Dropdown
          menu={{
            items: menuItems,
            onClick: (e) => {
              if (e.key === "Add Criteria") {
                onOpenManageCriteria();
              } else {
                onOpenManageOutcome();
              }
            },
          }}
          placement="bottomLeft"
          getPopupContainer={() =>
            document.getElementById("sub-react-root") as HTMLElement
          }
        >
          <Button type="primary">
            <PlusCircleFilled />
          </Button>
        </Dropdown>
      ) : (
        <Button type="primary">
          <PlusCircleFilled />
        </Button>
      )}
      {modal}
      {outcomeModal}
    </div>
  );
};
export default observer(AddFlowNode);
