import { courseServiceUrl } from "../../config";
import { CreateAxiosDefaults } from "axios";
import { BaseApiClient, InitProps } from "./BaseApiClient";
import { message } from "antd";

export class CourseServiceApiClient extends BaseApiClient {
  public static init(config?: CreateAxiosDefaults & InitProps): void {
    super.init({
      ...config,
      baseURL: courseServiceUrl,
      response: (config) => config?.data,
      responseError: (err: any) => {
        message.error(
          err.response?.data?.message ||
            err.response?.data?.error?.message ||
            err.message
        );
      },
    });
  }
}
