import { CreateAxiosDefaults } from "axios";
import { BaseApiClient, InitProps } from "./BaseApiClient";
import { userServiceUrl } from "../../config";
import { message } from "antd";

export class UserServiceApiClient extends BaseApiClient {
  public static init(config?: CreateAxiosDefaults & InitProps): void {
    super.init({
      ...config,
      baseURL: userServiceUrl,
      request: (config) => config,
      response: (config) => config?.data,
      responseError: (err: any) => {
        message.error(
          (err.response.data as any)?.message ||
            (err.response.data as any)?.error?.message ||
            err.message
        );
      },
    });
  }
}
