import { useInfiniteScroll, useRequest } from "ahooks";
import { Button, Form, Modal, Radio, Select, Skeleton, Space } from "antd";
import { FC, useState } from "react";
import { executeEligibilityFlow } from "../../api/course/course.api";
import SearchSelect from "../SearchSelect";
import { getUserList } from "../../api/user/user.api";
import { SystemUser } from "../../api/user/types";
import { AdminCell } from "../UserCell/AdminCell";
import { debounce } from "lodash";
import {
  FlowNodeCommand,
  FlowNodeType,
  FlowOutcomeCommand,
} from "../../mobx/type";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { colors } from "../../constant";
import { observer } from "mobx-react";
import { useAppStore } from "../../mobx";
import React from "react";

interface Result {
  list: SystemUser[];
  total: number;
}

interface Props {
  courseId: string;
}

const TestFlow: FC<Props> = (props) => {
  const { courseId } = props;
  const [formInModal] = Form.useForm();
  const { currentNodes } = useAppStore();

  const { runAsync: testFlow, loading: testLoading } = useRequest(
    executeEligibilityFlow,
    {
      manual: true,
    }
  );

  const [name, setName] = useState<string>();

  const [open, setOpen] = useState(false);
  const [openOutcome, setOpenOutcome] = useState(false);
  const [learntHistory, setLearntHistory] = useState<FlowNodeCommand>();
  const [outcome, setOutcome] = useState<{
    studentId?: string;
    eligibility: boolean;
    message: string;
  }>();

  const { data, loading, loadMore, loadingMore } = useInfiniteScroll(
    (d) => {
      const pageIndex = d ? Math.ceil(d.list.length / 20) : 0;
      return getLoadMoreList(pageIndex);
    },
    {
      reloadDeps: [name],
    }
  );

  function getLoadMoreList(pageIndex: number): Promise<Result> {
    return new Promise(async (resolve) => {
      const result = await getUserList(
        { name: name ? name : undefined },
        { pageIndex }
      );
      resolve({
        list: result?.items,
        total: result?.total,
      });
    });
  }

  const onTestFlow = async (value?: {
    studentId: string;
    nodeNo?: number;
    userResponse?: boolean;
  }): Promise<FlowNodeCommand[]> => {
    const result = await testFlow(courseId, {
      studentId: value?.studentId as string,
      nodeNo: value?.nodeNo ? value?.nodeNo : undefined,
      userResponse: value?.nodeNo ? value?.userResponse : undefined,
    });
    const lastNode = result[result?.length - 1];

    if (lastNode?.nodeNo && lastNode?.type === FlowNodeType.LearntHistory) {
      return result;
    }

    if (lastNode?.nodeNo && lastNode?.type !== FlowNodeType.Outcome) {
      return onTestFlow({
        studentId: value?.studentId as string,
        nodeNo: lastNode?.nodeNo as number,
        userResponse: value?.userResponse,
      });
    } else {
      return result;
    }
  };

  const handleTestFlow = async (value?: {
    studentId: string;
    nodeNo?: number;
    userResponse: boolean;
  }) => {
    try {
      const result = await onTestFlow({
        studentId: value?.studentId as string,
        userResponse: value?.userResponse,
        nodeNo: learntHistory?.nodeNo ? learntHistory?.nodeNo : undefined,
      });
      const lastNode = result[result?.length - 1];

      if (lastNode.type === FlowNodeType.LearntHistory) {
        setLearntHistory(lastNode);
        formInModal.resetFields(["userResponse"]);
      } else {
        setOutcome({
          ...(lastNode?.outcome as FlowOutcomeCommand),
          studentId: value?.studentId,
        });
        onCancel();
        setOpenOutcome(true);
      }
    } catch (error) {}
  };

  const onCancel = () => {
    formInModal.resetFields();
    setOpen(false);
    setLearntHistory(undefined);
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        size="middle"
      >
        Test Flow
      </Button>

      <Modal
        title="Test Flow"
        okText="Test"
        cancelText="Cancel"
        onOk={() => {
          formInModal.submit();
        }}
        open={open}
        closable
        confirmLoading={testLoading}
        onCancel={onCancel}
        getContainer={() =>
          document.getElementById("sub-react-root") as HTMLElement
        }
      >
        <Form
          form={formInModal}
          onFinish={(value) => {
            handleTestFlow(value);
          }}
          layout="vertical"
          validateMessages={{ required: "Field is required." }}
        >
          <Form.Item
            name="studentId"
            label={
              <span className="font-semibold text-sm">Select Student</span>
            }
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SearchSelect
              placeholder=" "
              optionLabelProp="label"
              loading={loading || loadingMore}
              onChange={() => {
                setLearntHistory(undefined);
              }}
              loadingElement={
                <div className="flex flex-col gap-2">
                  {new Array(3).fill("").map((item, index) => (
                    <Skeleton
                      key={`skeleton-${index}`}
                      avatar={{ shape: "circle" }}
                      title
                      paragraph={false}
                      active
                    />
                  ))}
                </div>
              }
              onClear={() => {
                setName("");
              }}
              onScroll={loadMore}
              onSearch={debounce((val) => {
                setName(val);
              }, 300)}
            >
              {data?.list?.map((i: SystemUser, index) => (
                <Select.Option
                  key={i?._id + index}
                  value={i?._id}
                  label={
                    <AdminCell
                      avatarSize={24}
                      profile={i}
                      showDescription={false}
                      allowJump={false}
                    />
                  }
                >
                  <AdminCell
                    avatarSize={24}
                    profile={i}
                    showDescription
                    description={i?.userId}
                    allowJump={false}
                  />
                </Select.Option>
              ))}
            </SearchSelect>
          </Form.Item>
          {learntHistory?.learntHistoryExpression?.question ? (
            <Form.Item
              key={learntHistory?.learntHistoryExpression?.question}
              name="userResponse"
              label={
                <span className="font-semibold text-sm">
                  {learntHistory?.learntHistoryExpression?.question}
                </span>
              }
              rules={[
                {
                  required: true,
                },
              ]}
              className="!mb-0"
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
      </Modal>

      <Modal
        title="Test Flow"
        okText="Done"
        cancelText="Back"
        onOk={() => {
          setOpenOutcome(false);
        }}
        open={openOutcome}
        closable
        onCancel={() => {
          setOpenOutcome(false);
        }}
        cancelButtonProps={{
          onClick: () => {
            setOpenOutcome(false);
            setOpen(true);
          },
        }}
        getContainer={() =>
          document.getElementById("sub-react-root") as HTMLElement
        }
      >
        <AdminCell
          avatarSize={48}
          profile={data?.list?.find((i) => i?._id === outcome?.studentId)}
          allowJump={false}
        />
        <div className="font-semibold text-sm my-3">Result:</div>
        <div>
          {outcome?.eligibility ? (
            <div className="flex items-baseline font-semibold">
              <CheckCircleFilled
                style={{ color: colors.semantics.green }}
                className="!pl-0 pr-1"
              />
              Eligible
            </div>
          ) : (
            <div className="flex items-baseline font-semibold">
              <CloseCircleFilled
                style={{ color: colors.semantics.red }}
                className="!pl-0 pr-1"
              />
              Not Eligible
            </div>
          )}

          <div
            style={{
              backgroundColor: "#EAF2FF",
            }}
            className="py-2 mt-2 overflow-auto rounded-md"
          >
            <div className="px-4">
              <span className="font-semibold">Message:</span> {outcome?.message}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default observer(TestFlow);
