import { observer } from "mobx-react";
import CriteriaNode from "./Criteria/CriteriaNode";
import Tree from "react-d3-tree";
import { CustomNodeElementProps } from "react-d3-tree/lib/types/types/common";
import { useAppStore } from "../../mobx";
import RootNode from "./Root/RootNode";
import AddFlowNode from "./AddFlowNode";
import { FlowNodeType } from "../../mobx/type";
import OutcomeNode from "./Outcome/OutcomeNode";

function TreeNode() {
  const { currentNodes } = useAppStore();

  const drawStepPath = function (linkData: any, orientation: any) {
    var source = linkData.source,
      target = linkData.target;
    var deltaY = target.y - source.y;
    const paths = "M"
      .concat(source.x, ",")
      .concat(source.y, " V")
      .concat(source.y + deltaY / 2 + 65, " H")
      .concat(target.x, " V")
      .concat(target.y);
    return paths;
  };

  const renderForeignObjectNode = ({ nodeDatum }: CustomNodeElementProps) => {
    if (nodeDatum?.attributes?.isTopNode) {
      return (
        <g>
          <foreignObject {...{ width: 345, height: 120, y: 0, x: -170 }}>
            <RootNode nodeDatum={nodeDatum} />
          </foreignObject>
        </g>
      );
    }

    if (!nodeDatum?.attributes?.type) {
      return (
        <g>
          <foreignObject {...{ width: 70, height: 70, x: -25, y: -25 }}>
            <AddFlowNode nodeDatum={nodeDatum} />
          </foreignObject>
        </g>
      );
    }

    if (nodeDatum?.attributes?.type === FlowNodeType.Outcome) {
      return (
        <g>
          <foreignObject {...{ width: 345, height: 240, x: -170, y: -25 }}>
            <OutcomeNode nodeDatum={nodeDatum} />
          </foreignObject>
        </g>
      );
    }

    return (
      <g>
        <foreignObject
          {...{
            width: 345,
            height: nodeDatum?.name === "start" ? 150 : 170,
            x: -170,
            y: -20,
          }}
        >
          <CriteriaNode nodeDatum={nodeDatum} />
        </foreignObject>
      </g>
    );
  };

  return (
    <div
      id="treeWrapper"
      className="flex flex-col items-center w-screen h-screen"
    >
      <Tree
        data={currentNodes}
        centeringTransitionDuration={800}
        orientation="vertical"
        translate={{ x: window.innerWidth / 2 - 250, y: 50 }}
        zoomable={false}
        draggable={true}
        collapsible={false}
        pathFunc={drawStepPath}
        separation={{ siblings: 4, nonSiblings: 4 }}
        depthFactor={220}
        renderCustomNodeElement={(rd3tProps: CustomNodeElementProps) =>
          renderForeignObjectNode({ ...rd3tProps })
        }
      />
    </div>
  );
}

export default observer(TreeNode);
