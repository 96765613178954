/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Form, Input, Modal, Segmented, Select, Space } from "antd";
import { useMemo, useState } from "react";
import {
  AcademicStreamCheckExpressionCommand,
  FlowNodeCommand,
  FlowNodeType,
  flowNodeTypeOptions,
  LogicalOperator,
} from "../../../mobx/type";
import { useAppStore } from "../../../mobx";
import { TreeNodeDatum } from "react-d3-tree/lib/types/types/common";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import moment from "moment";

export const getInitialValues = (nodeDatum: TreeNodeDatum) => {
  if (nodeDatum?.attributes?.type === FlowNodeType.AcademicStream) {
    const academicStreamCheckExpressions = JSON.parse(
      nodeDatum?.attributes?.academicStreamCheckExpressions as string
    );
    return {
      items: academicStreamCheckExpressions?.map((i: any) => ({
        ...i,
        type: FlowNodeType.AcademicStream,
      })),
    };
  }
  if (nodeDatum?.attributes?.type === FlowNodeType.EnrollmentHistory) {
    const enrollmentHistoryCheckExpressions = JSON.parse(
      nodeDatum?.attributes?.enrollmentHistoryCheckExpressions as string
    );

    return {
      items: enrollmentHistoryCheckExpressions?.map((i: any) => ({
        ...i,
        value: i?.courseId,
        time: i?.startDate
          ? [moment(i?.startDate).startOf("D"), moment(i?.endDate).endOf("D")]
          : null,
        type: FlowNodeType.EnrollmentHistory,
      })),
    };
  }
  if (nodeDatum?.attributes?.type === FlowNodeType.LearntHistory) {
    const learntHistoryExpression = JSON.parse(
      nodeDatum?.attributes?.learntHistoryExpression as string
    );
    return {
      items: [
        {
          ...learntHistoryExpression,
          type: FlowNodeType.LearntHistory,
        },
      ],
    };
  }
};

const useManageCriteria = (
  nodeDatum: TreeNodeDatum,
  isEditCriteria: boolean = false
) => {
  const [form] = Form.useForm();

  const { addCriteria, editCriteria, references, courses } = useAppStore();

  const [visible, setVisible] = useState(false);
  const [operator, setOperator] = useState<LogicalOperator>(
    (nodeDatum?.attributes?.operator as LogicalOperator) || LogicalOperator.Or
  );

  const flowNodeType: FlowNodeType = Form.useWatch(["items", 0, "type"], form);

  const handleSubmit = (value: any) => {
    const { items } = value;

    const flow: FlowNodeCommand = {
      type: items?.[0]?.type,
      operator: items?.length > 1 ? operator : undefined,
    };
    if (items?.[0]?.type === FlowNodeType.AcademicStream) {
      flow.academicStreamCheckExpressions = items?.map(
        (i: AcademicStreamCheckExpressionCommand) => {
          return {
            value: i?.value,
            operator: i?.operator,
          };
        }
      );
    }
    if (items?.[0]?.type === FlowNodeType.EnrollmentHistory) {
      flow.enrollmentHistoryCheckExpressions = items?.map((i: any) => {
        return {
          courseId: i?.value,
          operator: i?.operator,
          startDate: i?.time?.[0]
            ? moment(i?.time?.[0]).format("D MMM YYYY")
            : null,
          endDate: i?.time?.[1]
            ? moment(i?.time?.[1]).format("D MMM YYYY")
            : null,
        };
      });
    }

    if (items?.[0]?.type === FlowNodeType.LearntHistory) {
      flow.learntHistoryExpression = {
        question: items?.[0]?.question,
      };
    }

    if (isEditCriteria) {
      editCriteria(nodeDatum, flow);
    } else {
      addCriteria(nodeDatum, flow);
    }

    onCancel();
  };

  const operatorOptions = useMemo(() => {
    if (flowNodeType === FlowNodeType.AcademicStream) {
      return [
        {
          label: "Is",
          value: LogicalOperator.Is,
        },
        {
          label: "Is not",
          value: LogicalOperator.IsNot,
        },
      ];
    }
    if (flowNodeType === FlowNodeType.EnrollmentHistory) {
      return [
        {
          label: "Contains",
          value: LogicalOperator.Contain,
        },
        {
          label: "Does not contain",
          value: LogicalOperator.NotContain,
        },
      ];
    }
  }, [flowNodeType]);

  const valueOptions = useMemo(() => {
    if (flowNodeType === FlowNodeType.AcademicStream) {
      return references?.map((i) => ({ value: i?.key, label: i?.value }));
    }
    if (flowNodeType === FlowNodeType.EnrollmentHistory) {
      return courses?.map((i) => ({
        value: i?._id,
        label: i?.nameShort,
      }));
    }
  }, [flowNodeType]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };
  const modal = (
    <Modal
      title="Manage Criteria"
      okText="Save"
      cancelText="Cancel"
      getContainer={() =>
        document.getElementById("sub-react-root") as HTMLElement
      }
      width={1000}
      onOk={() => {
        form.submit();
      }}
      open={visible}
      onCancel={onCancel}
      destroyOnClose
      closable
    >
      <Form
        form={form}
        className="w-full manage-criteria-form flex items-end"
        colon={false}
        style={{ maxWidth: "none" }}
        layout="inline"
        labelAlign="left"
        onFinish={handleSubmit}
        initialValues={{ items: [{}] }}
        validateMessages={{ required: "Field is required." }}
        onFinishFailed={({ errorFields }) => {
          if (errorFields?.[0]?.name.includes("courseFee")) {
            form.scrollToField("courseFee");
          } else {
            form.scrollToField(errorFields[0].name);
          }
        }}
      >
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <div key={`form-items-${key}`}>
                  {index > 0 ? (
                    <Segmented
                      className="!mt-6"
                      value={operator}
                      onChange={(value) => {
                        setOperator(value as LogicalOperator);
                      }}
                      options={[
                        { value: LogicalOperator.And, label: "AND" },
                        { value: LogicalOperator.Or, label: "OR" },
                      ]}
                    />
                  ) : (
                    <></>
                  )}
                  <Space
                    key={`form-items-space-${key}`}
                    style={{ display: "flex", marginBottom: 8 }}
                    size={0}
                    align="end"
                  >
                    <Form.Item
                      name={[name, "type"]}
                      label="User Data"
                      className="w-56 flex flex-col"
                      style={{ width: "230px" }}
                      rules={[{ required: true }]}
                    >
                      <Select
                        disabled={index > 0}
                        options={flowNodeTypeOptions}
                        className="w-full !text-sm"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        popupClassName="!text-sm"
                        onChange={(val) => {
                          form.setFieldsValue({ items: [{ type: val }] });
                        }}
                      />
                    </Form.Item>
                    {flowNodeType !== FlowNodeType?.LearntHistory && (
                      <>
                        <Form.Item
                          name={[name, "operator"]}
                          label={"Operator"}
                          className="w-36"
                          rules={[{ required: true }]}
                        >
                          <Select
                            options={operatorOptions}
                            className="w-36 !text-sm"
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            popupClassName="!text-sm"
                          />
                        </Form.Item>
                        <Form.Item
                          name={[name, "value"]}
                          label={"Value"}
                          className="w-48"
                          rules={[{ required: true }]}
                        >
                          <Select
                            options={valueOptions}
                            className="w-48 !text-sm"
                            popupClassName="!text-sm"
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            showSearch
                            filterOption={(input, option) => {
                              if (typeof option?.label === "string") {
                                return (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }
                              return true;
                            }}
                          />
                        </Form.Item>
                      </>
                    )}

                    {flowNodeType === FlowNodeType?.EnrollmentHistory && (
                      <Form.Item
                        name={[name, "time"]}
                        label={"Time Frame"}
                        className="w-64"
                      >
                        <DatePicker.RangePicker
                          className="w-full !text-sm"
                          format="D MMM YYYY"
                          popupClassName="!text-sm"
                          getPopupContainer={(triggerNode: any) =>
                            triggerNode.parentNode
                          }
                        />
                      </Form.Item>
                    )}

                    {flowNodeType === FlowNodeType?.LearntHistory && (
                      <Form.Item
                        name={[name, "question"]}
                        label={"Custom Question"}
                        className="w-100"
                        rules={[{ required: true }]}
                      >
                        <Input className="w-64 !text-sm" />
                      </Form.Item>
                    )}

                    {index > 0 ? (
                      <div
                        className="flex items-center h-full mr-2"
                        style={{ paddingBottom: "15px" }}
                      >
                        <MinusCircleFilled
                          className="w-5 h-5 text-5"
                          onClick={() => remove(name)}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </Space>
                </div>
              ))}
              {flowNodeType !== FlowNodeType?.LearntHistory && (
                <Form.Item className="h-full" style={{ paddingBottom: "6px" }}>
                  <PlusCircleFilled
                    className="w-5 h-5 text-5"
                    onClick={() => {
                      form
                        .validateFields([["items", 0, "type"]])
                        .then((res) => {
                          add({ type: res.items[0].type });
                        })
                        .catch(() => {});
                    }}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );

  return {
    modal,
    onOpenManageCriteria: () => {
      setVisible(true);
    },
    onSetFormValue: (value: any) => {
      form.setFieldsValue(value);
    },
  };
};

export default useManageCriteria;
