/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { SettingFilled } from "@ant-design/icons";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { colors } from "../../../constant";
import { useAppStore } from "../../../mobx";
import uuid from "react-uuid";
import { isEmpty } from "lodash";
import { Dropdown } from "antd";
import { TreeNodeDatum } from "react-d3-tree/lib/types/types/common";
import { ReactComponent as IconDelete } from "../../../assets/IconDelete.svg";
import { ReactComponent as IconEdit } from "../../../assets/IconEdit.svg";
import {
  AcademicStreamCheckExpressionCommand,
  EnrollmentHistoryCheckExpressionCommand,
  FlowNodeType,
  LearntHistoryCheckExpressionCommand,
  LogicalOperator,
} from "../../../mobx/type";
import { openConfirmModal } from "../../ConfirmModal";
import useManageCriteria, { getInitialValues } from "./useManageCriteria";
import { listToMap } from "../../../utils/listToMap";
import moment from "moment";

export interface NodeProps {
  nodeDatum: TreeNodeDatum;
}

const formatOperator = (operator: LogicalOperator) => {
  const operators = [
    {
      label: "Is",
      value: LogicalOperator.Is,
    },
    {
      label: "Is not",
      value: LogicalOperator.IsNot,
    },
    {
      label: "Contains",
      value: LogicalOperator.Contain,
    },
    {
      label: "Does not contain",
      value: LogicalOperator.NotContain,
    },
  ];

  return operators?.find((i) => i?.value === operator)?.label;
};

function CriteriaNode({ nodeDatum }: NodeProps) {
  const { isOpenEligibilityFlow, removeNode, references, courses } =
    useAppStore();
  const [currentId, setCurrentId] = useState("");
  const { modal, onOpenManageCriteria, onSetFormValue } = useManageCriteria(
    nodeDatum,
    true
  );

  const referencesMap = listToMap(references, "key");
  const coursesMap = listToMap(courses, "_id");

  useEffect(() => {
    if (isEmpty(currentId)) {
      setCurrentId(uuid());
    }
  }, [currentId]);

  const content = useMemo(() => {
    if (nodeDatum?.attributes?.type === FlowNodeType.AcademicStream) {
      const result: AcademicStreamCheckExpressionCommand[] = JSON.parse(
        nodeDatum?.attributes?.academicStreamCheckExpressions as string
      );
      return (
        <div>
          If <span className="font-semibold">Student’s Academic Stream</span>{" "}
          {result
            ?.map(
              (i: any) =>
                `${formatOperator(i?.operator)} ${
                  referencesMap.get(i?.value)?.value
                }`
            )
            ?.join(
              (nodeDatum?.attributes?.operator as string)
                ? ` ${nodeDatum?.attributes?.operator} `
                : ""
            )}
        </div>
      );
    }
    if (nodeDatum?.attributes?.type === FlowNodeType.EnrollmentHistory) {
      const result: EnrollmentHistoryCheckExpressionCommand[] = JSON.parse(
        nodeDatum?.attributes?.enrollmentHistoryCheckExpressions as string
      );
      return (
        <div>
          If <span className="font-semibold">Student Enrollment History</span>{" "}
          {result
            ?.map((i: any) => {
              if (i?.startDate) {
                return `${formatOperator(i?.operator)} ${
                  coursesMap.get(i?.value || i?.courseId)?.nameShort
                } from ${moment(i?.startDate).format("D MMM YYYY")} to ${moment(
                  i?.endDate
                ).format("D MMM YYYY")} `;
              }
              return `${formatOperator(i?.operator)} ${
                coursesMap.get(i?.value || i?.courseId)?.nameShort
              } `;
            })
            ?.join(
              (nodeDatum?.attributes?.operator as string)
                ? ` ${nodeDatum?.attributes?.operator} `
                : ""
            )}
        </div>
      );
    }

    const result: LearntHistoryCheckExpressionCommand = JSON.parse(
      nodeDatum?.attributes?.learntHistoryExpression as string
    );

    return <div>{result?.question}</div>;
  }, [nodeDatum?.attributes]);

  return (
    <div
      className="h-inherit flex items-center flex-col"
      onScroll={(event) => {
        event?.stopPropagation();
      }}
      onMouseEnter={(event) => {
        event?.stopPropagation();
        event?.preventDefault();
      }}
    >
      {nodeDatum.name !== "start" && (
        <>
          {nodeDatum?.attributes?.childrenType === "passNode" && (
            <span className="ml-8">Yes</span>
          )}
          {nodeDatum?.attributes?.childrenType === "failNode" && (
            <span className="ml-8">No</span>
          )}
        </>
      )}
      <div className="flex bg-white rounded-default flex-col flex-1 w-full">
        <div className="flex flex-row justify-between h-14 p-4 border-b-default border-b-gray-400">
          <div className="flex flex-row items-center">
            <img
              style={{ width: "22px", height: "22px" }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHFSURBVHgB7ZpBUsJAEEV/B9xzBI/AEeAGLC1lkZSle0/C3lKDVchWbgBH4Ah4g2xcAdMOKUtJISmH6aEc7LfJhvD5yUz37wRAUZSQEITo59yBQQc+JJiPMppAABFjV4+c20MKGYYv15TBE29j/QfuMeEVgpBBd3RDM3iQwBdCG9IknksaEsb+KGosNtRYbKix2FBjseFvzGAGaWwYhifexspMx3iGFPa7JBK+3Nhiw7B3brR33zf8njxid6yOzRDKBtNSUGAk+Q1aFWPjZI014chlzm17NVou59j99VUtOUHb7jm4YIBinJFTb3M2ljAGzF6j+8AadYKoDAFdl3N0Ke5AmNMadwgINzCwSeSgpn+4MbvuQ/ej/hMXbrvxGy33seFszC6NN3tY2MpYIDDH1IqGnRCc5txaGqTk2IS3sVe6OEswGWa0qPtcSK2KsfSe26sGpuwhtEXBa3THtz8nhtBalT22bCIXEtrQoqbtQ3sIrVUtHiz85qQuegXW0nIfG2osNtRYbKix2Pgfxj4fmshB+9+ahNaqGDOr8hmGyOxDm2C6wt6/DoXW2hlbLnI+bxj0fEeJ9wTDSUa1P/yYWoqiyPIBlrynwxtffOUAAAAASUVORK5CYII="
            />
            <div className="ml-2 text-base font-semibold">Criteria</div>
          </div>

          {isOpenEligibilityFlow && (
            <Dropdown
              overlayClassName="w-32"
              menu={{
                items: [
                  {
                    label: "Edit",
                    key: "Edit",
                    icon: <IconEdit className="cursor-pointer" />,
                  },
                  {
                    label: "Delete",
                    key: "Delete",
                    icon: <IconDelete className="cursor-pointer" />,
                  },
                ],
                onClick: (info) => {
                  if (info.key === "Delete") {
                    openConfirmModal({
                      title: "Remove Node",
                      callback: async () => {
                        removeNode(nodeDatum.name);
                      },
                    });
                  } else {
                    onOpenManageCriteria();
                    onSetFormValue(getInitialValues(nodeDatum));
                  }
                },
              }}
              getPopupContainer={() =>
                document.getElementById("sub-react-root") as HTMLElement
              }
            >
              <SettingFilled style={{ color: colors.primary.navy }} />
            </Dropdown>
          )}
        </div>
        <div className="p-4 flex-1 overflow-hidden">
          <div className="overflow-auto max-h-16">{content}</div>
        </div>
      </div>
      {modal}
    </div>
  );
}

export default observer(CriteriaNode);
