/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react";
import { FC } from "react";
import { useAppStore } from "../../mobx";
import TreeNode from "../Node/TreeNode";
import { Empty } from "antd";

const EligibilityFlowContent: FC = () => {
  const { currentFlow, isOpenEligibilityFlow } = useAppStore();

  return (
    <div
      key="eligibility-flow-content"
      className="bg-gray-300 flex flex-1 rounded-default flex-col p-6 overflow-hidden"
      style={{ minWidth: "600px" }}
    >
      {!currentFlow?._id && !isOpenEligibilityFlow ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ marginTop: "25%" }}
          description={
            <div className="flex items-center justify-center flex-col">
              <div>No course eligibility </div><div>set up yet</div>
            </div>
          }
        />
      ) : (
        <TreeNode />
      )}
    </div>
  );
};

export default observer(EligibilityFlowContent);
