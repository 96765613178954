import { observer } from "mobx-react";
import { FC } from "react";
import { useAppStore } from "../../mobx";
import { checkNodesType, convertNodesToParams } from "../../mobx/utils";
import { useRequest } from "ahooks";
import {
  checkEligibilityFlowEnable,
  createEligibilityFlow,
  updateEligibilityFlow,
} from "../../api/course/course.api";
import { RawNodeDatum } from "react-d3-tree/lib/types/types/common";
import { Breadcrumb, Button, Divider, message, Space, Switch } from "antd";
import { openConfirmModal } from "../ConfirmModal";
import { CourseEligibilityFlowStatus } from "../../mobx/type";
import EnabledBadge from "../Badge/EnabledBadge";
import DisabledBadge from "../Badge/DisabledBadge";
import AuthorizedByPermission from "../Authorize/AuthorizedByPermission";
import { OAuthPermission } from "../Authorize/permission";
import TestFlow from "./TestFlow";
import { isEmpty } from "lodash";
import React from "react";

interface Props {
  refresh?: () => void;
}
const EligibilityFlowHeader: FC<Props> = (props) => {
  const { refresh } = props;
  const {
    currentCourseId,
    courseDetail,
    resetNodes,
    currentNodes,
    currentFlow,
    isOpenEligibilityFlow,
    setOpenEligibilityFlow,
  } = useAppStore();

  const { loading: createLoading, runAsync: create } = useRequest(
    createEligibilityFlow,
    {
      manual: true,
      onSuccess() {
        refresh?.();
        message.success("Save Course Eligibility Check Successfully!");
      },
    }
  );
  const { loading: updatedLoading, runAsync: update } = useRequest(
    updateEligibilityFlow,
    {
      manual: true,
      onSuccess() {
        refresh?.();
        message.success("Save Course Eligibility Check Successfully!");
      },
    }
  );

  const { runAsync: check } = useRequest(checkEligibilityFlowEnable, {
    manual: true,
    onSuccess() {
      refresh?.();
    },
  });

  const handleSave = () => {
    openConfirmModal({
      callback: async () => {
        const bool = checkNodesType(currentNodes.children?.[0] as RawNodeDatum);
        if (
          bool &&
          currentFlow?.status === CourseEligibilityFlowStatus.Active
        ) {
          message.error(
            "Cannot enable the eligibility check, at least one criteria and its corresponding outcome are required."
          );
          return;
        }
        const startNode = convertNodesToParams(
          currentNodes.children?.[0] as RawNodeDatum
        );
        try {
          if (currentFlow?._id) {
            await update({
              id: currentFlow._id,
              startNode: isEmpty(startNode) ? undefined : startNode,
            });
          } else {
            await create({
              courseId: currentCourseId,
              startNode: isEmpty(startNode) ? undefined : startNode,
            });
          }
          setOpenEligibilityFlow(false);
        } catch (error) {}
      },
    });
  };

  const handleDiscard = () => {
    openConfirmModal({
      title: "Discard",
      callback: async () => {
        setOpenEligibilityFlow(false);
        if (!currentFlow?._id) {
          resetNodes();
        } else {
          refresh?.();
        }
      },
    });
  };

  const handleEnableCheck = (val: boolean) => {
    if (currentFlow?._id) {
      openConfirmModal({
        title: "Enable Check",
        callback: async () => {
          if (val) {
            const bool = checkNodesType(
              currentNodes.children?.[0] as RawNodeDatum
            );
            if (bool) {
              message.error(
                "Cannot enable the eligibility check, at least one criteria and its corresponding outcome are required."
              );
              return;
            }
          }
          await check(currentFlow?._id);
          setOpenEligibilityFlow(false);
        },
      });
    }
  };

  return (
    <div
      className="flex flex-col"
      style={{ marginBottom: "34px" }}
      key="eligibility-flow-header"
    >
      <Breadcrumb separator=">" className="font-semibold cursor-pointer">
        <Breadcrumb.Item
          onClick={() => {
            window.location.href = `${window.location?.origin}/course-management/course/${courseDetail._id}`;
          }}
        >
          {courseDetail.nameShort}
        </Breadcrumb.Item>
        <Breadcrumb.Item>Manage Eligibility Check</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex flex-shrink-0 flex-row mt-5 justify-between items-baseline">
        <div className="text-6 font-semibold flex items-center">
          Manage Eligibility Check
          <AuthorizedByPermission
            permissions={[OAuthPermission.COURSE_ELIGIBILITY_UPDATE]}
          >
            <>
              {currentFlow?._id && (
                <>
                  <Divider type="vertical" className="h-8" />{" "}
                  <Switch
                    disabled={isOpenEligibilityFlow}
                    checked={
                      currentFlow?.status === CourseEligibilityFlowStatus.Active
                    }
                    className="!mr-2"
                    onChange={handleEnableCheck}
                  />{" "}
                  {currentFlow?.status ===
                  CourseEligibilityFlowStatus.Active ? (
                    <EnabledBadge />
                  ) : (
                    <DisabledBadge />
                  )}
                </>
              )}
            </>
          </AuthorizedByPermission>
        </div>

        <AuthorizedByPermission
          permissions={[OAuthPermission.COURSE_ELIGIBILITY_UPDATE]}
        >
          <Space size={8}>
            {!currentFlow?._id && !isOpenEligibilityFlow ? (
              <Button
                type="primary"
                size="middle"
                onClick={() => {
                  setOpenEligibilityFlow(true);
                }}
              >
                Add Check Flow
              </Button>
            ) : (
              <></>
            )}

            {currentFlow?._id && !isOpenEligibilityFlow ? (
              <>
                <TestFlow courseId={courseDetail?._id} />
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => {
                    setOpenEligibilityFlow(true);
                  }}
                >
                  Edit
                </Button>
              </>
            ) : (
              <></>
            )}

            {isOpenEligibilityFlow ? (
              <>
                <Button onClick={handleDiscard} size="middle">
                  Discard
                </Button>
                <Button
                  onClick={handleSave}
                  loading={createLoading || updatedLoading}
                  type="primary"
                  size="middle"
                >
                  Save
                </Button>
              </>
            ) : (
              <></>
            )}
          </Space>
        </AuthorizedByPermission>
      </div>
    </div>
  );
};

export default observer(EligibilityFlowHeader);
