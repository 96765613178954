import React from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../UserAvatar/UserAvatar";

interface AdminCellProps {
  profile: {
    _id?: string;
    fullName: string;
    gender?: string;
    avatarUrl?: string;
    salutation?: string;
    initials?: string;
    alias?: string;
  };
  avatarSize?: number;
  description?: string;
  allowJump?: boolean;
  showDescription?: boolean;
}

export const AdminCell: React.FC<AdminCellProps> = (props) => {
  const {
    profile: { fullName, gender, avatarUrl, initials, alias, _id },
    avatarSize = 36,
    description,
    allowJump = true,
    showDescription = true,
  } = props;

  return (
    <div className="flex space-x-2 items-center font-semibold">
      <Link to={_id && allowJump ? `/profile/${_id}` : ""} target="_blank">
        <UserAvatar
          name={fullName}
          avatarUrl={avatarUrl}
          size={avatarSize}
          gender={gender}
        />
      </Link>
      <div>
        {_id && allowJump ? (
          <Link
            className="text-primary-blue"
            to={_id ? `/profile/${_id}` : ""}
            target="_blank"
          >
            <div>{fullName}</div>
          </Link>
        ) : (
          <div>{fullName}</div>
        )}

        {showDescription && (
          <div className="text-primary-navyLight">
            {description || initials || alias}
          </div>
        )}
      </div>
    </div>
  );
};
