export enum FlowNodeType {
  Outcome = "OUTCOME",
  AcademicStream = "ACADEMIC_STREAM",
  EnrollmentHistory = "ENROLLMENT_HISTORY",
  LearntHistory = "LEARNT_HISTORY",
}

export enum LogicalOperator {
  Or = "OR",
  And = "AND",
  Is = "IS",
  IsNot = "IS_NOT",
  Contain = "CONTAIN",
  NotContain = "NOT_CONTAIN",
}

export type AcademicStreamCheckExpressionCommand = {
  value: string;
  operator: LogicalOperator;
};

export type EnrollmentHistoryCheckExpressionCommand = {
  courseId: string;
  startDate: Date;
  endDate: Date;
  operator: LogicalOperator;
};

export type LearntHistoryCheckExpressionCommand = {
  question: string;
};

export type FlowOutcomeCommand = {
  eligibility: boolean;
  message: string;
};

export type FlowNodeCommand = {
  type: FlowNodeType;

  passNode?: FlowNodeCommand;

  failNode?: FlowNodeCommand;

  academicStreamCheckExpressions?: AcademicStreamCheckExpressionCommand[];

  enrollmentHistoryCheckExpressions?: EnrollmentHistoryCheckExpressionCommand[];

  learntHistoryExpression?: LearntHistoryCheckExpressionCommand;

  outcome?: FlowOutcomeCommand;

  operator?: LogicalOperator;

  nodeNo?: number;
};

export type UpdateCourseEligibilityFlowCommand = {
  id: string;
  startNode: FlowNodeCommand;
};

export type CreateCourseEligibilityFlowCommand = {
  courseId: string;
  startNode: FlowNodeCommand;
};

export enum CourseEligibilityFlowStatus {
  Draft = "DRAFT",
  Active = "ACTIVE",
}

export type CourseEligibilityFlow = {
  _id: string;
  createdBy?: string;
  lastModifiedBy?: string;
  isDeleted: boolean;
  deletedAt?: Date;
  createDateTime: Date;
  lastModifiedDateTime?: Date;
  session?: any;
  isModified?: boolean;
  courseId: string;
  nodeNo?: number;
  startNode: FlowNodeCommand;
  status: CourseEligibilityFlowStatus;
  type: FlowNodeType;
};

export const flowNodeTypeOptions = [
  {
    label: "Student Academic Stream",
    value: FlowNodeType.AcademicStream,
  },
  {
    label: "Student Enrollment History",
    value: FlowNodeType.EnrollmentHistory,
  },
  {
    label: "Student Learnt History",
    value: FlowNodeType.LearntHistory,
  },
];
export const operatorOptions = [
  {
    label: "Is",
    value: LogicalOperator.Is,
  },
  {
    label: "Is not",
    value: LogicalOperator.IsNot,
  },
];
