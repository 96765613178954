/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { FC, useEffect } from "react";
import { useAppStore } from "../../mobx";
import Loading from "../Loading";
import { useRequest } from "ahooks";
import {
  getCourse,
  getCourses,
  getEligibilityFlow,
  getReferences,
} from "../../api/course/course.api";
import EligibilityFlowContent from "./EligibilityFlowContent";
import EligibilityFlowHeader from "./EligibilityFlowHeader";
import { ReferenceDataCategory } from "../../api/course/types";

const EligibilityFlow: FC = () => {
  const {
    initialCurrentNodes,
    setEligibilityFlow,
    setCourseDetail,
    currentCourseId,
    resetNodes,
    setCourses,
    setReferences,
    setOpenEligibilityFlow,
  } = useAppStore();

  const {
    loading: flowLoading,
    runAsync,
    refresh,
  } = useRequest(getEligibilityFlow, {
    manual: true,
    onSuccess(data) {
      setEligibilityFlow(data);
      if (isEmpty(data?.startNode)) {
        resetNodes();
        return;
      }
      initialCurrentNodes(data.startNode);
    },
    onError(e, params) {
      resetNodes();
    },
  });

  const {
    data: courseData,
    loading: courseLoading,
    run: runCourse,
  } = useRequest(getCourse, {
    manual: true,
    onSuccess: async (data) => {
      setOpenEligibilityFlow(false);
      await runAsync(data?._id);
      setCourseDetail({
        _id: data?._id,
        nameShort: data?.nameShort ?? "",
        avatarUrl: data?.avatarUrl ?? "",
      });
    },
  });

  const { loading: coursesLoading } = useRequest(getCourses, {
    defaultParams: [{ pageIndex: 0, pageSize: 0 }],
    onSuccess(data) {
      setCourses(data?.items || []);
    },
  });

  const { loading: referencesLoading } = useRequest(getReferences, {
    defaultParams: [ReferenceDataCategory.AcademicStream],
    onSuccess(data) {
      setReferences(data || []);
    },
  });

  useEffect(() => {
    if (!isEmpty(currentCourseId)) {
      runCourse(currentCourseId);
    }
  }, [currentCourseId]);

  if (
    courseLoading ||
    !courseData?._id ||
    flowLoading ||
    coursesLoading ||
    referencesLoading
  ) {
    return <Loading />;
  }

  return (
    <React.Fragment key="eligibility-flow">
      <EligibilityFlowHeader refresh={refresh} />
      <EligibilityFlowContent />
    </React.Fragment>
  );
};

export default observer(EligibilityFlow);
