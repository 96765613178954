/* eslint-disable */
export const Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,

  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_API_AUDIENCE_CORE_SERVICE,
    scope: process.env.REACT_APP_AUTH0_SCOPES,
    organization: process.env.REACT_APP_AUTH0_ORGANIZATION,
    redirect_uri: window.location.origin,
  },

  useRefreshTokens: true,
};
export const courseServiceUrl =
  process.env.REACT_APP_ARCHUS_COURSE_SERVICE_ENDPOINT ||
  "http://localhost:7102/api";

export const userServiceUrl =
  process.env.REACT_APP_ARCHUS_USER_SERVICE_ENDPOINT ||
  `http://localhost:7103/api`;

export const SentryConfig = {
  AdminDSN: process.env.REACT_APP_ADMIN_DSN,
  TeacherDSN: process.env.REACT_APP_TEACHER_DSN,
};

export const xeroCallBackUrlFlag = /accounting.budgets.read/;

export const IsPermissionDebugMode =
  process.env.REACT_APP_PERMISSION_DEBUG_MODE === "true";
