import {
  CourseEligibilityFlow,
  CreateCourseEligibilityFlowCommand,
  FlowNodeCommand,
  UpdateCourseEligibilityFlowCommand,
} from "../../mobx/type";
import { CourseServiceApiClient } from "../clients/CourseServiceApiClient";
import {
  Course,
  ExecuteCourseEligibilityFlowQuery,
  Page,
  PageQuery,
  ReferenceData,
  ReferenceDataCategory,
} from "./types";

export class CourseApi {
  private static courseServiceClient = CourseServiceApiClient;

  private static coursesPathPrefix = "/v1/courses";

  public static async getCourses(
    pageQuery: PageQuery = {}
  ): Promise<Page<Course>> {
    return this.courseServiceClient.get(`${this.coursesPathPrefix}`, {
      ...pageQuery,
    });
  }

  public static async getEligibilityFlow(
    id: string
  ): Promise<CourseEligibilityFlow> {
    return this.courseServiceClient.get(
      `${this.coursesPathPrefix}/${id}/eligibility-flow`
    );
  }
}

export const getEligibilityFlow = async (
  id: string
): Promise<CourseEligibilityFlow> =>
  await CourseServiceApiClient.get(`/v1/courses/${id}/eligibility-flow`);

export const getCourse = async (id: string): Promise<Course> =>
  await CourseServiceApiClient.get(`/v1/courses/${id}`);

export const getCourses = async (
  pageQuery: PageQuery = {}
): Promise<Page<Course>> =>
  await CourseServiceApiClient.post(`/v1/courses/list`, null, {
    ...pageQuery,
  });

export const getReferences = async (
  category: ReferenceDataCategory
): Promise<ReferenceData[]> =>
  await CourseServiceApiClient.get(`/v1/references`, { category });

export const createEligibilityFlow = async (
  command: CreateCourseEligibilityFlowCommand
): Promise<CourseEligibilityFlow> =>
  await CourseServiceApiClient.post(`/v1/eligibility-flows`, command);

export const updateEligibilityFlow = async (
  command: UpdateCourseEligibilityFlowCommand
): Promise<CourseEligibilityFlow> =>
  await CourseServiceApiClient.put(`/v1/eligibility-flows`, command);

export const checkEligibilityFlowEnable = async (
  id: string
): Promise<CourseEligibilityFlow> =>
  await CourseServiceApiClient.put(`/v1/eligibility-flows/${id}/enable`);

export const executeEligibilityFlow = async (
  id: string,
  query: ExecuteCourseEligibilityFlowQuery
): Promise<FlowNodeCommand[]> =>
  await CourseServiceApiClient.get(`/v1/courses/${id}/execute-eligibility-flow`,query);
