/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { enableLogging } from "mobx-logger";
import { useLocation } from "react-router-dom";
import actions from "./actions";
import { useAppStore } from "./mobx";
import { isEmpty, last, replace } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import jwtDecode from "jwt-decode";
import Loading from "./components/Loading";
import EligibilityFlow from "./components/EligibilityFlow/EligibilityFlow";
import { AxiosClient } from "./api/clients";
enableLogging({ action: true, reaction: true });

function App() {
  const location = useLocation();
  const { userToken, setUserToken, setCurrentCourseId } = useAppStore();

  useEffect(() => {
    //@ts-ignore
    if (!window.__POWERED_BY_QIANKUN__) {
      const items = location?.search?.split("=");
      if (!isEmpty(items)) {
        setCurrentCourseId(last(items) as string);
      }
    }
    actions.onGlobalStateChange((state: any) => {
      const token = replace(state.token, "Bearer ", "");
      setUserToken(token);
      AxiosClient.setAuthorization(token as string);
      setCurrentCourseId(state.courseId);
    }, true);
  }, [location]);
  const {
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    getIdTokenClaims,
  } = useAuth0();
  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();
      await getIdTokenClaims();
      sessionStorage.setItem("token", token);
      setUserToken(token);
      AxiosClient.setAuthorization(token);
    };
    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated]);
  useEffect(() => {
    //@ts-ignore
    if (!window.__POWERED_BY_QIANKUN__) {
      const token = sessionStorage.getItem("token");
      if (isEmpty(token)) {
        loginWithRedirect();
      } else {
        const decoded: { exp: number; permissions: string[] } = jwtDecode(
          token ?? ""
        );

        if (moment(decoded.exp * 1000).isBefore()) {
          loginWithRedirect();
        } else {
          setUserToken(token ?? "");
        }
        AxiosClient.setAuthorization(token as string);
      }
    }
  }, []);

  return !isEmpty(userToken) && !isLoading ? (
    // @ts-ignore
    <div className={`App ${window.__POWERED_BY_QIANKUN__ ? "!p-0" : ""}`}>
      <EligibilityFlow />
    </div>
  ) : (
    <Loading />
  );
}

export default observer(App);
