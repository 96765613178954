import { FlagFilled } from "@ant-design/icons";
import { colors } from "../../../constant";
import { TreeNodeDatum } from "react-d3-tree/lib/types/types/common";
import { FC } from "react";
import { useAppStore } from "../../../mobx";

export interface NodeProps {
  nodeDatum: TreeNodeDatum;
}
const RootNode: FC<NodeProps> = ({ nodeDatum }) => {
  const { courseDetail } = useAppStore();
  
  return (
    <div style={{ width: "345px" }} className=" flex items-center flex-col">
      <div className="flex bg-white rounded-default flex-col flex-1 w-full">
        <div className="flex flex-row justify-between flex-1 p-4 border-b-default border-b-gray-400">
          <div className="flex flex-row items-center">
            {nodeDatum.name === "root" && (
              <FlagFilled style={{ color: colors.primary.red }} />
            )}

            <div className="ml-2 text-base font-semibold">Trigger</div>
          </div>
        </div>
        <div className="p-4">Student enrolls in {courseDetail.nameShort}</div>
      </div>
    </div>
  );
};

export default RootNode;
