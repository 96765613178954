/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Modal, Radio } from "antd";
import { useEffect, useState } from "react";
import { useAppStore } from "../../../mobx";
import { TreeNodeDatum } from "react-d3-tree/lib/types/types/common";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { colors } from "../../../constant";

const useManageOutcome = (
  nodeDatum: TreeNodeDatum,
  isEditOutcome: boolean = false
) => {
  const [form] = Form.useForm();

  const { TextArea } = Input;

  const { addOutcome, editOutcome } = useAppStore();

  const [visible, setVisible] = useState(false);

  const handleSubmit = (value: any) => {
    addOutcome(nodeDatum, value);
    setVisible(false);

    if (isEditOutcome) {
      editOutcome(nodeDatum, value);
    } else {
      addOutcome(nodeDatum, value);
    }
  };

  useEffect(() => {
    if (isEditOutcome && nodeDatum?.attributes?.outcome) {
      const outcome = JSON.parse(nodeDatum?.attributes?.outcome as string);
      form.setFieldsValue({ ...outcome });
    }
  }, [isEditOutcome, nodeDatum]);

  const eligibility = Form.useWatch("eligibility", form);

  const modal = (
    <Modal
      title="Manage Outcome"
      okText="Save"
      cancelText="Cancel"
      getContainer={() =>
        document.getElementById("sub-react-root") as HTMLElement
      }
      width={520}
      onOk={() => {
        form.submit();
      }}
      open={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closable
    >
      <Form
        form={form}
        className="w-full manage-criteria-form"
        colon={false}
        labelAlign="left"
        onFinish={handleSubmit}
        initialValues={{ eligibility: true }}
        validateMessages={{ required: "Field is required." }}
        onFinishFailed={({ errorFields }) => {
          if (errorFields?.[0]?.name.includes("courseFee")) {
            form.scrollToField("courseFee");
          } else {
            form.scrollToField(errorFields[0].name);
          }
        }}
      >
        <Form.Item
          name="eligibility"
          className="!mb-0"
          rules={[{ required: true }]}
        >
          <Radio.Group className="flex flex-wrap">
            <Radio value={true} className="flex !items-start">
              <div className="flex items-stretch font-semibold">
                <CheckCircleFilled
                  style={{ color: colors.semantics.green }}
                  className="!pl-0 pr-1"
                />
                Eligible
              </div>
            </Radio>
            <Radio value={false} className="flex !items-start">
              <div className="flex items-stretch font-semibold">
                <CloseCircleFilled
                  style={{ color: colors.semantics.red }}
                  className="!pl-0 pr-1"
                />
                Not Eligible
              </div>
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="message"
          label={eligibility ? "Message (Optional)" : "Message"}
          dependencies={["eligibility"]}
          className="!mb-0"
          rules={[{ required: !eligibility }]}
        >
          <TextArea autoSize={{ maxRows: 4, minRows: 3 }} />
        </Form.Item>
      </Form>
    </Modal>
  );

  return {
    modal,
    onOpenManageOutcome: () => {
      setVisible(true);
    },
  };
};

export default useManageOutcome;
