import { CourseServiceApiClient } from "./CourseServiceApiClient";
import { UserServiceApiClient } from "./UserServiceApiClient";

export class AxiosClient {
  public static init(): void {
    CourseServiceApiClient.init();
    UserServiceApiClient.init();
  }

  public static setAuthorization(token: string): void {
    CourseServiceApiClient.setAuthorization(token);
    UserServiceApiClient.setAuthorization(token);
  }
}
